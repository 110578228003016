var balkongskydd = [
  {
    type: 'utomhus',
    category: 'Balkongskydd',
    name: 'Höj och sänkbar',
    description: `Gör din balkong till ett uterum. Skydda din balkong mot insyn, sol och
      vind. Vi anpassar och monterar skydden efter dina önskemål.  Balkongskyddet kan fås i följande material. Glasfiber, Glasklar plast och
      Markisväv.`,
    modalText: `<h2>Höj och sänkbart balkongskydd</h2>
      <p>
        Gör din balkong till ett uterum. Skydda din balkong mot insyn, sol och
        vind. Vi anpassar och monterar skydden efter dina önskemål
      </p>
      <p>
        Balkongskyddet kan fås i följande material. Glasfiber, Glasklar plast och
        Markisväv.`,
    mainImage: {
      src: '/images/products/utomhus/balkongskydd/balkongskydd.jpg',
      alt: 'Balkongskydd',
    },
    images: [
      {
        src: '/images/products/utomhus/balkongskydd/hojsank2.jpg',
        alt: 'Balkongskydd',
      },
      {
        src: '/images/products/utomhus/balkongskydd/hojsank1.jpg',
        alt: 'Balkongskydd',
      },
      {
        src: '/images/products/utomhus/balkongskydd/matinstruktion.jpg',
        alt: 'Mätinstruktion',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Balkongskydd',
    name: 'Balkongskydd i väv',
    description: `Skydda din balkong mot insyn och vind. Vi anpassar och monterar skyddet
      efter dina önskemål.`,
    mainImage: {
      src: '/images/products/utomhus/balkongskyddvav.jpg',
      alt: 'Balkongskydd i väv',
    },
    images: [],
  },
  {
    type: 'utomhus',
    category: 'Balkongskydd',
    name: 'Maxi Balkongskydd',
    description: `Maxi vindskydd för balkonger ger dig en skönare plats att vistas på.
    Lättmonterat och hållbart skydd som testats i månda år. Dras ut till
    valfri längd upp till 2,3 meter! Kan höjas och sänkas efter mellanrum
    mellan överliggare och nederdel på balkongen. Monteras enkelt på de flesta
    balkonger utan håltagning eller specialverktyg.`,
    modalText: `<h2>Maxi balkongskydd</h2>
      <p>
        Vind och insynsskydd för din balkong
      </p>
      <p>Maxi vindskydd för balkonger ger dig en skönare plats att vistas på.</p>
      <p>
        Lättmonterat och hållbart skydd som testats i månda år. Dras ut till
        valfri längd upp till 2,3 meter! Kan höjas och sänkas efter mellanrum
        mellan överliggare och nederdel på balkongen. Monteras enkelt på de flesta
        balkonger utan håltagning eller specialverktyg.
      </p>
      <h3>Maxi Balkongskydd Skönare komfort på din balkong!</h3>
      <p>
        Vind- och insynsskydd för din balkong. Lättmonterat - Du behöver inte
        borra eller använda specialverktyg. Passar de flesta balkongtyper. Väven
        fjäderbelastad - Alltid sträckt! Infällbart i kassett = lång hållbarhet.
        Flyttar du till annan balkong är det lätt att ta med skyddet dit. Fakta
        Maxi Balkongskydd: Maxlängd 2,3 meter, utdragbar och ställbar utmed sin
        längd. Höjd 60 cm. Skyddet går att höja och sänka för att täta mellan
        räcke och överliggare. Fjäderspänd väv i 100% acryl, rötsäker, tvättbar,
        ljusäkta och impregnerad. Finns som standard i Flera färger och som
        special i ett 100 tal färger! Stativ och kassett i anodiserad rostsäker
        aluminium. Fäste i gjutet aluminium spänns runt räcket (kan även skruvas i
        träräcken). Har tillverkats och utvecklats sedan 1970-talet. Hörnstolpe
        medföljer leveransen för att göra det enkelt att leda runt vinklade
        balkonger. På raka balkonger används stolpen för att staga upp skyddet.
        Utdragslängd är max 2,3 meter, och inom denna kan Du stanna var Du vill.
        MAXI är dessutom höj och sänkbart för att anpassas till olika räcken.
        Bruksanvisning medföljer. Med flera hörnstag kan MAXI även monteras på
        rundade balkonger. Normalt monteras MAXI alltid på utsidan av balkongen
        för att utrymme på balkongen inte skall förloras.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/balkongskydd/maxi_big.jpg',
      alt: 'Maxi balkongskydd',
    },
    images: [
      {
        src: '/images/products/utomhus/balkongskydd/medium_beige-maxi.jpg',
        alt: 'Balkongskydd medium beige',
      },
    ],
  },
]

module.exports = balkongskydd

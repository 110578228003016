<template>
  <div class="m-0 p-0 w-100 flex-grow-1">
    <Flash :svgColor="bgColor" />
    <b-container tag="main" fluid class="p-0">
      <b-container tag="section" fluid class="mb-5 bg-discount">
        <b-container tag="article" class="py-5">
          <h2 class="h2">Rabatter</h2>
          <p>
            A-Solskydd har ett rabattavtal med HSB i Stockholm. Som medlem i HSB
            har Du rabatterade priser på våra solskyddsprodukter.
          </p>
          <p>
            När Du kontaktar oss vänligen ange att Du är HSB-medlem och Ditt
            medlemsnummer.
          </p>
        </b-container>
      </b-container>
      <b-container tag="section" class="mb-5">
        <h2 class="h2" id="offer">Offert</h2>
        <p>
          Om offert önskas vore vi tacksamma att Du beaktar följande i Din
          förfrågan:
        </p>
        <h3 class="h3">För invändiga produkter</h3>
        <p>
          Måtta bredd och höjd över det område som ska täckas av solskyddet.
          Cirkamått räcker, och glöm inte ange önskat antal för produkt med
          samma mått.
        </p>
        <h3 class="h3">För utvändiga produkter</h3>
        <p>
          Fönstermarkis; måtta bredd och fönstrets halva höjd. Terrassmarkis;
          måtta bredd och det utfall som markisen ska täcka. Balkongmarkis;
          måtta balkongens bredd. Höj och sänkbar balkongskydd; måtta längs
          räcket för det område som ska täckas. Cirkamått räcker i samtliga
          fall.
        </p>
        <section class="form-widht mx-auto">
          <contact />
        </section>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import Flash from '@/components/Flash'
import Contact from '@/components/Contact'

export default {
  name: 'Home',
  data() {
    return {
      bgColor: '#edecf6',
    }
  },
  components: {
    Flash,
    Contact,
  },
}
</script>
<style lang="scss" scoped>
.form-widht {
  width: 50%;
}

.bg-discount {
  background-color: #edecf6;
}

@media screen and (max-width: 990px) {
  .form-widht {
    width: 100%;
  }
}

.h1,
.h2,
p,
form,
label,
input,
.h3,
img {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

var plisse = require('./products/plisser')
var persienner = require('./products/persienner')
var markiser = require('./products/markiser')
var terassmarkis = require('./products/terassmarkis')
var vertikalmarkis = require('./products/vertikalmarkis')
var balkongskydd = require('./products/balkongskydd')
var balkongmarkis = require('./products/balkongmarkis')
var fasadpersienn = require('./products/fasadpersienn')
var quartzHeater = require('./products/quartzheater')
var sidoMarkis = require('./products/sidomarkis')
var lamellGardiner = require('./products/lamellGardin')
var duetter = require('./products/duetter')
var insectnet = require('./products/insectnet')
var rullgardiner = require('./products/rullgardiner')
var securityfilm = require('./products/securityfilm')
var solfilm = require('./products/solfilm')

var products = []
products.push(...persienner)
products.push(...duetter)
products.push(...markiser)
products.push(...fasadpersienn)
products.push(...terassmarkis)
products.push(...vertikalmarkis)
products.push(...balkongmarkis)
products.push(...insectnet)
products.push(...balkongskydd)
products.push(...quartzHeater)
products.push(...sidoMarkis)
products.push(...lamellGardiner)
products.push(...plisse)
products.push(...rullgardiner)
products.push(...securityfilm)
products.push(...solfilm)

module.exports = products

var duetter = [
  {
    type: 'inomhus',
    category: 'Duette',
    name: 'Duette',
    description: `Duette® är en Hunter Douglas produkt. 
      Gardinerna har ett honungskakemönster vilket ger dem flera unika egenskaper.
       Bland annat gör det Duette® till en av de mest energieffektiva gardinerna
        på marknaden, och även ljuddämpande`,
    modalText: `<h2>Duette</h2>
      <p>Duette® är en Hunter Douglas produkt. 
      Gardinerna har ett honungskakemönster vilket ger dem flera unika egenskaper.
       Bland annat gör det Duette® till en av de mest energieffektiva 
       gardinerna på marknaden, och även ljuddämpande. 
       Duette® finns i många olika färger, stilar och storlekar, 
       vilket gör dem flexibla nog att passa i praktiskt taget alla utrymmen. 
       Det är en skräddarsydd produkt som kan distribueras via
        flera återförsäljare i Sverige. <a href="http://www.duette.se"> Läs mer på deras hemsida</a><p>`,
    link: 'http://www.duette.se',
    mainImage: {
      src: '/images/products/inomhus/duetter/duette.jpg',
      alt: '50 mm persienn',
    },
    images: [],
    video: 'https://www.youtube.com/embed/HSRhtiZuius',
  },
]

module.exports = duetter

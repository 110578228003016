<template>
  <b-container fluid id="app" class="d-flex flex-column">
    <Navigation />
    <router-view />
    <b-button
      v-b-toggle.sidebar-right
      class="contact-btn"
      variant="primary"
      size="lg"
    >
      Kontakta oss
    </b-button>
    <b-sidebar id="sidebar-right" title="Kontakta oss" right shadow>
      <p class="m-3">
        Fyll i din frågan nedan så kontaktar vi dig så snart som möjligt. För
        offert förfrågan se följande sidan för yttligare information
        <b-link href="/#offer">Länk till offert &gt;&gt;</b-link>
      </p>
      <p class="m-3">
        Ni kan även ringa oss på telefon:<br />
        <b-link class="link-syle" href="tel:+46739863050">
          <p>
            <b-icon icon="telephone-outbound"></b-icon>
            073-986 30 50
          </p>
        </b-link>
      </p>
      <Contact />
    </b-sidebar>
    <Footer />
  </b-container>
</template>

<script>
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'
import Contact from '@/components/Contact'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
    Contact,
  },
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.contact-btn {
  position: fixed;
  right: -50px;
  top: 50%;
  z-index: 1;
  transform: rotate(90deg);
}
</style>

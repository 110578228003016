var rullgardiner = [
  {
    type: 'inomhus',
    category: 'Rullgardiner',
    name: 'Rullgardin',
    description: `För komfort och atmosfär. Egentligen är det märkligt att något så trivialt
      som rullgardiner kan ha så stor betydelse för en människas välbefinnande i
      hus och hem runt om i Stockholm. En god natts sömn hjälper oss att ladda
      batterierna och ger oss energi.`,
    modalText: `<h2>Rullgardiner</h2>
      <BigImage :image="mainImage" />
      <p>
        För komfort och atmosfär. Egentligen är det märkligt att något så trivialt
        som rullgardiner kan ha så stor betydelse för en människas välbefinnande i
        hus och hem runt om i Stockholm. En god natts sömn hjälper oss att ladda
        batterierna och ger oss energi. Men många av oss har svårt att få
        tillräcklig vila när ljuset utifrån tar sovrummet i besittning. Men en
        rullgardin är inte bara effektiv som ljusreglerare, den kan också vara
        mycket dekorativ. Använd fantasin och låt rullgardinerna ingå som en del
        av inredningen! Hitta din personliga stil i vårt breda textilsortiment. Vi
        använder oss av kvalitetstextilier från Almedahls i Kinna till våra
        rullgardiner. För dig som har barn och söker barnsäkra rullgardiner
        rekommenderas modellen Slowrise eftersom den uppfyller EU:s senaste
        säkerhetskrav för rullgardiner.
      </p>`,
    mainImage: {
      src: '/images/products/inomhus/rullgardin/rull.jpg',
      alt: 'rullgardin',
    },
    images: [
      {
        src: '/images/products/inomhus/rullgardin/rull_duo.jpg',
        alt: 'rull duo',
      },
      {
        src: '/images/products/inomhus/rullgardin/rull_motor.jpg',
        alt: 'rull motor',
      },
      {
        src: '/images/products/inomhus/rullgardin/rull_sidodrag.jpg',
        alt: 'rull sidodrag',
      },
      {
        src: '/images/products/inomhus/rullgardin/rull_sidokanal.jpg',
        alt: 'rull sidokanal',
      },
      {
        src: '/images/products/inomhus/rullgardin/rull_standard.jpg',
        alt: 'rull standard',
      },
      {
        src: '/images/products/inomhus/rullgardin/rull_vitabeslag.jpg',
        alt: 'rull vita beslag',
      },
    ],
  },
  {
    type: 'inomhus',
    category: 'Rullgardiner',
    name: 'Mjuk upprullning till önskad position',
    description: `Glöm rullgardiner som oväntat far upp med en smäll och skrämmer slag på
de som är nära. Dra ner Slowrise en liten bit så rullar den upp sig
själv – mjukt och fint. Du kan själv justera hastigheten. Slowrise är
lätt att anpassa till omgivningen. För dig som har barn är Slowrise ett
tryggt val. EU har särskilda säkerhetskrav för rullgardiner i miljöer
där barn vistas. Krav som Slowrise uppfyller.`,
    modalText: `<h2>Barnsäkra rullgardiner</h2>
    <h3>Slowrise rullgardin - mjuk upprullning till önskad position</h3>
    <p>
      GSM Rullgardin Slowrise Glöm rullgardiner som oväntat far upp med en smäll
      och skrämmer slag på de som är nära. Dra ner Slowrise en liten bit så
      rullar den upp sig själv - mjukt och fint. Slowrise är den barnsäkra
      rullgardinen som anpassar sig. Den kan steglöst ställas in att stanna där
      du vill, många gillar att "spara" några decimeter. Slowrise anpassar sig
      också till omgivningen. Det finns väldigt många kulörer och mönster att
      välja på! För dig som har barn är Slowrise ett tryggt val. EU har
      föreslagit nya säkerhetskrav för rullgardiner i miljöer där barn vistas
      och Slowrise uppfyller givetvis dessa krav.
    </p>`,
    mainImage: {
      src: '/images/products/inomhus/rullgardin/rullgardin.jpg',
      alt: 'Slow rise',
    },
    images: [],
  },
]

module.exports = rullgardiner

<template>
  <div class="accordion" role="tablist">
    <b-card no-body border-variant="light" class="mb-0">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.indoor variant="primary">
          Invändiga
        </b-button>
      </b-card-header>
      <b-collapse
        v-for="(category, index) in indoorCat"
        :key="index"
        id="indoor"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body body-border-variant="light">
          <b-card no-body class="mb-0" border-variant="light">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle:[category] variant="primary">
                {{ category }}
              </b-button>
            </b-card-header>
            <b-collapse :id="category" accordion="accordion" role="tabpanel">
              <b-card-body body-border-variant="light">
                <div class="d-flex justify-content-around flex-wrap">
                  <b-card
                    :title="prod.name"
                    :img-src="prod.mainImage.src"
                    :img-alt="prod.mainImage.alt"
                    img-top
                    img-height="30%"
                    border-variant="light"
                    style="max-width: 15rem;"
                    class="mb-4 shadow "
                    v-for="(prod, index) in indoor[category]"
                    :key="index"
                    ><b-card-text>{{ prod.description }}</b-card-text>
                  </b-card>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body border-variant="light" class="mb-0">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.outdoor variant="primary">
          Utvändiga
        </b-button>
      </b-card-header>
      <b-collapse
        v-for="(category, index) in outdoorCat"
        :key="index"
        id="outdoor"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body body-border-variant="light">
          <b-card no-body class="mb-0" border-variant="light">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle:[category] variant="primary">
                {{ category }}
              </b-button>
            </b-card-header>
            <b-collapse :id="category" accordion="accordion" role="tabpanel">
              <b-card-body body-border-variant="light">
                <div class="d-flex justify-content-around flex-wrap">
                  <b-card
                    :title="prod.name"
                    :img-src="prod.mainImage.src"
                    :img-alt="prod.mainImage.alt"
                    img-top
                    img-height="30%"
                    border-variant="light"
                    style="max-width: 15rem;"
                    class="mb-4 shadow "
                    v-for="(prod, index) in outdoor[category]"
                    :key="index"
                    ><b-card-text>{{ prod.description }}</b-card-text>
                  </b-card>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    products: {
      type: Array,
    },
  },
  data() {
    return {
      categories: [],
      productsToShow: [],
      indoor: [],
      outdoor: [],
      indoorCat: [],
      outdoorCat: [],
    }
  },
  created() {
    this.categories = [
      ...new Set(
        this.products
          .filter(item => item.type === 'inomhus')
          .map(item => item.category)
      ),
    ]
    this.outdoor = _.groupBy(
      this.products.filter(item => item.type === 'utomhus'),
      'category'
    )
    this.indoor = _.groupBy(
      this.products.filter(item => item.type === 'inomhus'),
      'category'
    )
    this.indoorCat = [
      ...new Set(
        this.products
          .filter(item => item.type === 'inomhus')
          .map(item => item.category)
      ),
    ]
    this.outdoorCat = [
      ...new Set(
        this.products
          .filter(item => item.type === 'utomhus')
          .map(item => item.category)
      ),
    ]
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (
        isJustShown === true &&
        collapseId !== 'Invändiga' &&
        collapseId !== 'Utvändiga'
      ) {
        setTimeout(() => {
          document.getElementById(collapseId).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          })
        }, 100)
      }
    })
  },
}
</script>

<style scoped>
.accordion .card-body {
  padding: 0;
}
.accordion .card {
  margin: 0;
}
div#indoor.collapse.show button.btn.btn-primary.btn-block.collapsed,
div#outdoor.collapse.show button.btn.btn-primary.btn-block.collapsed {
  background-color: #8f79df;
  border-color: #8f79df;
}
</style>

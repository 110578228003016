<template>
  <div class="flash flash-hero">
    <img :src="imageLink" :alt="imgAlt" class="img-hero w-100 h-100" />
    <h1 class="text">{{ text }}</h1>
    <svg
      preserveAspectRatio="none"
      class="svg-hero"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 220"
    >
      <path
        class="path-hero"
        width="100%"
        fill="#fff"
        fill-opacity="1"
        d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,122.7C1120,107,1280,117,1360,122.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    imageLink: {
      type: String,
    },
    imgAlt: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>
<style lang="scss">
.flash-hero {
  display: flex;
  flex-direction: column;
  height: 30em;
  max-width: 100%;
  padding: 0;
  background-color: rgb(12, 0, 53);
  position: relative;
  margin-bottom: 2em;
}

.img-hero {
  display: block;
  opacity: 0.7;
  z-index: 0;
}

.svg-hero {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.path-hero {
  z-index: 1;
  width: 100%;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 3.5em;
  text-shadow: 2px 2px 4px #000000;
}
@media screen and (max-width: 480px) {
  .flash {
    height: 15em;
  }
  .text {
    font-size: 3em;
  }
}

@media screen and (min-width: 1460px) {
  .flash {
    height: 40em;
  }
  .text {
    font-size: 5em;
  }
}
</style>

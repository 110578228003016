var insectnet = [
  {
    type: 'inomhus',
    category: 'Insäktsnät',
    name: 'Insäktsnät',
    description: `clearview - sveriges mest eleganta myggnätsdörrar och myggnätsfönster.`,
    link: 'http://www.clearviewsweden.se/',
    mainImage: {
      src: '/images/products/inomhus/insectnet.jpg',
      alt: '50 mm persienn',
    },
    images: [],
  },
  {
    type: 'utomhus',
    category: 'Insäktsnät',
    name: 'Insäktsnät',
    description: `clearview - sveriges mest eleganta myggnätsdörrar och myggnätsfönster.`,
    link: 'http://www.clearviewsweden.se/',
    mainImage: {
      src: '/images/products/utomhus/insectnet.jpg',
      alt: '50 mm persienn',
    },
    images: [],
  },
]

module.exports = insectnet

<template>
  <div class="flash">
    <ul class="slideshow">
      <li><span></span></li>
      <li><span>2</span></li>
      <li><span></span></li>
      <li><span></span></li>
      <li><span></span></li>
    </ul>
    <svg
      preserveAspectRatio="none"
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 220"
    >
      <path
        width="100%"
        :fill="svgColor"
        fill-opacity="1"
        d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,122.7C1120,107,1280,117,1360,122.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Flash',
  props: {
    svgColor: {
      type: String,
      default: '#fff',
    },
  },
}
</script>
<style scoped>
.flash {
  display: flex;
  flex-direction: column;
  height: 30em;
  max-width: 100%;
  padding: 0;
  background-color: black;
  /* margin-bottom: 2em; */
}

.slideshow {
  list-style-type: none;
  padding: 0;
  max-width: 100%;
}

/** SLIDESHOW **/
.slideshow,
.slideshow:after {
  width: 100%;
  height: 30em;
  left: 0;
  z-index: 0;
}

.slideshow li span {
  position: absolute;
  width: 100%;
  height: 30em;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 30s linear infinite 0s;
}

.slideshow li:nth-child(1) span {
  background-image: url('../assets/images/flash/004.jpg');
}
.slideshow li:nth-child(2) span {
  background-image: url('../assets/images/flash/010.jpg');
  animation-delay: 6s;
}
.slideshow li:nth-child(3) span {
  background-image: url('../assets/images/flash/011.jpg');
  animation-delay: 12s;
}
.slideshow li:nth-child(4) span {
  background-image: url('../assets/images/flash/053.jpg');
  animation-delay: 18s;
}
.slideshow li:nth-child(5) span {
  background-image: url('../assets/images/flash/037.jpg');
  animation-delay: 24s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  19% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

.svg {
  z-index: 1;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .flash,
  .slideshow,
  .slideshow:after,
  .slideshow li span {
    height: 15em;
  }
}

@media screen and (min-width: 1460px) {
  .flash,
  .slideshow,
  .slideshow:after,
  .slideshow li span {
    height: 40em;
  }
}
</style>

<template>
  <div>
    <hero :imageLink="img" :imgAlt="imgAlt" :text="text" />
    <b-container fluid="md" class="flex-grow-1" tag="main">
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-primary border-styling rounded-0 bg-transparent p-3"
        active-tab-class="tab-styling"
        content-class="mt-4"
        nav-class="border-bottom"
        justified
        pills
        v-if="isDesktop"
      >
        <b-tab
          v-for="type in typeOfProduct"
          :title="type"
          :key="type"
          v-model="tabIndex"
          @click="filterOnType(type)"
        >
          <b-tabs
            pills
            justified
            active-tab-class="tab-styling"
            content-class="mt-4"
          >
            <b-tab
              v-for="(category, index) in categories"
              :key="index"
              :title="category"
            >
              <div class="d-flex justify-content-around flex-wrap">
                <b-card
                  :title="prod.name"
                  :img-src="prod.mainImage.src"
                  :img-alt="prod.mainImage.alt"
                  img-top
                  img-height="50%"
                  border-variant="light"
                  style="max-width: 20rem;"
                  class="mb-4 shadow "
                  v-for="(prod, index) in productsToShow[category]"
                  :key="index"
                  ><b-card-text>{{ prod.description }}</b-card-text>
                  <b-link v-if="prod.link" :href="prod.link"
                    >Se mer på tillverkarens sida >></b-link
                  >
                </b-card>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
      <MobileProducts v-else :products="products" />
    </b-container>
  </div>
</template>

<script>
import MobileProducts from '@/components/MobileProducts'
import Hero from '@/components/Hero'
import products from '@/assets/products.js'
import _ from 'lodash'
const img = require('@/assets/images/hero/markiser.jpg')
const imgAlt = 'markiser'

export default {
  data: () => ({
    isDesktop: false,
    products: products,
    indoor: [],
    outdoor: [],
    categories: [],
    productsToShow: [],
    typeOfProduct: ['Invändiga', 'Utvändiga'],
    tabIndex: 0,
    img: img,
    imgAlt: imgAlt,
    text: 'Produkter',
  }),

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  created() {
    this.indoor = this.products.filter(item => item.type === 'inomhus')
    this.outdoor = this.products.filter(item => item.type === 'utomhus')
    this.productsToShow = _.groupBy(this.indoor, 'category')
    this.categories = [
      ...new Set(
        this.products
          .filter(item => item.type === 'inomhus')
          .map(item => item.category)
      ),
    ]
  },
  methods: {
    onResize() {
      this.isDesktop = window.innerWidth > 760
    },
    filterOnType(type) {
      if (type == 'Invändiga') {
        this.categories = [
          ...new Set(
            this.products
              .filter(item => item.type === 'inomhus')
              .map(item => item.category)
          ),
        ]
        this.productsToShow = _.groupBy(this.indoor, 'category')
      } else {
        this.categories = [
          ...new Set(
            this.products
              .filter(item => item.type === 'utomhus')
              .map(item => item.category)
          ),
        ]
        this.productsToShow = _.groupBy(this.outdoor, 'category')
      }
    },
  },

  components: {
    MobileProducts,
    Hero,
  },
}
</script>

<style lang="scss">
$bg-color-active: #4e3c93;
$border-color-active: #3419a0a6;
.border-styling {
  border-bottom: 2px solid $border-color-active;
  transition: all 0.3s ease-in-out;
}
.tab-styling {
  transition: all 0.3s ease-in-out;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 1;
  object-fit: cover;
}
.card-body {
  flex: 1 0 auto;
}
</style>

var markiser = [
  {
    type: 'utomhus',
    category: 'Markis',
    name: 'Korgmarkis',
    description: `En kraftig och flexibel korgmarkis för exempelvis restauranger, butiker,
      hotell och kontor. Det går att trycka text eller logotyp på markisen och
      på så vis förvandla den till en idealisk reklamplats. Ett tips är att
      belysa markisen underifrån så att budskapet framträder även efter mörkrets
      inbrott.`,
    modalText: `<h2>Korgmarkis</h2>
      <p>
        En kraftig och flexibel korgmarkis för exempelvis restauranger, butiker,
        hotell och kontor. Det går att trycka text eller logotyp på markisen och
        på så vis förvandla den till en idealisk reklamplats. Ett tips är att
        belysa markisen underifrån så att budskapet framträder även efter mörkrets
        inbrott.
      </p>
      <h3>Manövrering</h3>
      <p>
        BC 20 går att få som uppdragbar eller fast markis. Väljer du den som
        uppdragbar ingår manövrering med utvändig lina. Som tillval finns utvändig
        eller invändig manövrering med motor samt invändig manöverering med lina.
        Markis med motor går att komplettera med automatisk styrning till exempel
        fjärrkontroll och sol och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>
        Markisen monteras direkt på vägg. Antalet bågar baseras vanligtvis eller
        fönsterfoder.
      </p>
      <h3>Markisduk</h3>
      <p>
        Välj från en bred kollektion med allt från enfärgade och randiga vävar
        till designade mönster. Den löstagbara kappan går att få i val finns
        utvändig eller invändig både raka och vågiga former.
      </p>
      <h3>Hörn och bågar</h3>
      <p>
        Huvudkomponenterna för markisens design är de kraftiga hörnen och bågarna.
        Välj antingen hörnet med radie 35mm, 75mm eller 170mm, det ställbara
        hörnet 90-180° eller en kombination av hörn. Antalet bågar baseras
        vanligtvis på korgens utfall
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/korgmarkis_01.jpg',
      alt: 'Korgmarkis',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/korgmarkis_02.jpg',
        alt: 'Korgmarkis',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Markis',
    name: 'Fönstermarkis DA22-DA32',
    description: `DA22 och DA32 är moderna, kompakta och stilrena markiser som passar de
      flesta byggnader.`,
    modalText: `<h2>Fönstermarkiser DA22 & DA32</h2>
      <p>
        DA22 och DA32 är moderna, kompakta och stilrena markiser som passar de
        flesta byggnader. DA32 är kompletterad med en elegant aluminiumkassett som
        skyddar markisduken mot regn och smuts. Fallarmarna har justerbar
        fjäderspänning för rätt dukspänning. Invändig eller utvändig manövrering
        med polyesterband och som tillval kan markisen motoriseras.
      </p>
      <h3>DA22</h3>
      <p>
        Är en fallarmsmarkis utan kassett.
      </p>
      <h3>DA32</h3>
      <p>
        Är en fallarmsmarkis med kassett.
      </p>
      <h3>Manöverering</h3>
      <p>
        Utvändig eller invändig manövrering med dragband av polyester är standard.
        För lagring och skydd av bandet, kan markisen kompletteras med
        banduppsamlare. Som tillval finns manövrering med vev eller motor. Motorn
        kan kompletteras med automatiska styrningar, t ex fjärrkontroll eller sol-
        och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>
        Markisen monteras enkelt direkt på fönsterfodret, på vägg eller i nisch.
        Som tillval finns nischfästen som gör att markisen kan justeras 45–185 mm
        i djupled.
      </p>
      <h3>Fallarmar</h3>
      <p>
        Armprofiler är tillverkade av strängpressat aluminium och armkomponenter
        av pressgjutet zink. Fallarmar med justerbar spänning för optimal
        dukspänning. Vindsäkrade fallarmar rekommenderas om markisen kommer att
        utsättas för hård vind.
      </p>
      <h3>Markisduk</h3>
      <p>
        Välj från en bred kollektion med allt från enfärgade till randiga vävar.
      </p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 3 färger: Vitt, Grått och
        Svart med struktur. Med lite längre leveranstid kan man få stativet i
        vilken RAL-färg man vill. Detta för att matcha markisen med husets färg.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/fonstermarkis_22.jpg',
      alt: 'Fönstermarkis',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/da22.jpg',
        alt: 'Fönstermarkis DA22',
      },
      {
        src: '/images/products/utomhus/markis/da32.jpg',
        alt: 'Fönstermarkis DA32',
      },
      {
        src: '/images/products/utomhus/markis/design_profil.jpg',
        alt: 'Design profil',
      },
      {
        src: '/images/products/utomhus/markis/standard_profil.jpg',
        alt: 'Standard profil',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Markis',
    name: 'Fönstermarkis DA42-DA45',
    description: `En kraftig markis med fallarmar som är konstruerad för att passa de flesta
      byggnader, till exempel villor, butiker, kontor, skolor och sjukhus.`,
    modalText: `<h2>Fönstermarkiser DA42 & DA45</h2>
      <p>
        En kraftig markis med fallarmar som är konstruerad för att passa de flesta
        byggnader, till exempel villor, butiker, kontor, skolor och sjukhus. DA42
        och 45 är perfekta att motorisera och därav mycket lämplig för stora
        motoriserade anläggningar. Aluminiumkassett skyddar duken mot regn och
        smuts.
      </p>
      <h3>Manöverering</h3>
      <p>
        Utvändig eller invänding manövrering med band är standard. Som tillval
        finns utvändig eller invändig manövrering med antingen vev eller motor.
        Det går också att komplettera motorn med automatiska styrningar, till
        exempel fjärrkontroll eller sol- och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>
        Markiserna monteras enkelt direkt på fönsterfoder, vägg eller i nisch.
        Nischkonsoler som tillval tillåter 45–185 mm justering i djupled.
      </p>
      <h3>Fallarmar</h3>
      <p>
        Armprofiler är tillverkade av strängpressat aluminium och armkomponenter
        av pressgjuten zink. Fallarmarna har justerbar spänning för optimal
        dukspänning. Vindsäkrade fallarmar rekommenderas om markisen kommer att
        utsättas för hård vind.
      </p>
      <h3>Markisduk</h3>
      <p>
        Välj från en bred kollektion med allt från enfärgade till randiga vävar.
      </p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 3 färger: Vitt, Grått och
        Svart med struktur. Med lite längre leveranstid kan man få stativet i
        vilken RAL-färg man vill. Detta för att macha markisen med husets färg.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/fonstermarkis_42.jpg',
      alt: 'Fönstermarkis',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/armfaste_da42.jpg',
        alt: 'Fönstermarkis DA42',
      },
      {
        src: '/images/products/utomhus/markis/armfaste_da45.jpg',
        alt: 'Fönstermarkis DA45',
      },
      {
        src: '/images/products/utomhus/markis/design_profil.jpg',
        alt: 'Design profil',
      },
      {
        src: '/images/products/utomhus/markis/standard_profil.jpg',
        alt: 'Standard profil',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Markis',
    name: 'Fönstermarkis DA52',
    description: `En stabil markis särskilt lämpad för byggnader med större fönsterpartier,
      till exempel butiker, kontor, skolor och sjukhus. Fallarmarna är extra
      kraftiga och har justerbar fjäderspänning för optimal dukspänning.
      Frontprofil och valsrör är också förstärkta. Takprofil som skyddar duk och
      valsrör ingår.`,
    modalText: `<h2>Fönstermarkiser 52</h2>
      <p>
        En stabil markis särskilt lämpad för byggnader med större fönsterpartier,
        till exempel butiker, kontor, skolor och sjukhus. Fallarmarna är extra
        kraftiga och har justerbar fjäderspänning för optimal dukspänning.
        Frontprofil och valsrör är också förstärkta. Takprofil som skyddar duk och
        valsrör ingår.
      </p>
      <h3>Manöverering</h3>
      <p>
        Utvändig manövrering med vev är standard. Som tillval finns manövrering
        med band eller motor, såväl invändigt som utvändigt. Vid motordrift går
        det att komplettera med automatiska styrningar, till exempel fjärrkontroll
        eller soloch vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>
        DA 52 monteras enkelt direkt på fönsterfoder, vägg eller under tak fot.
      </p>
      <h3>Fallarmar</h3>
      <p>
        Armprofiler är tillverkade strängpressat aluminium av och armkomponenter
        av pressgjutet zink. Extra kraftiga fjädrar med justerbar spänning för
        optimal dukspänning.
      </p>
      <h3>Markisduk</h3>
      <p>
        Välj från en bred kollektion med allt från enfärgade och randiga vävar.
      </p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 3 färger: Vitt, Grått och
        Svart med struktur. Med något längre leveranstid kan man få stativet i
        ytterligare 10 färger, matchade för att passa ihop med de vanligast
        förekommande husfärger och fasader.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/da52.jpg',
      alt: 'Fönstermarkis',
    },
    images: [],
  },
  {
    type: 'utomhus',
    category: 'Markis',
    name: 'Fönstermarkis MT64 & MT74',
    description: `En stabil markis som lämpar sig för byggnader med höga fönster - allt från
      butiker och kontor till sjukhus och skolor.`,
    modalText: `<h2>Fönstermarkiser MT64 & MT74</h2>
      <p>
        En stabil markis som lämpar sig för byggnader med höga fönster - allt från
        butiker och kontor till sjukhus och skolor. Duken löper vertikalt längs
        den övre delen av fönstret för att vid ett visst läge fälla ut sig som en
        vanlig markis. Det ger ett mycket bra skydd mot solen, samtidigt som man
        får fri sikt genom fönstret. Dukar finns i ett flertal varianter, till
        exempel ljus- och väderbeständiga och genomsiktliga.
      </p>
      <h3>Manöverering</h3>
      <p>
        MT64 manövreras som standard med vevbox, MT74 med utvändig vev. Som
        tillval finns manövrering med motor. Vid motordrift går det att
        komplettera med automatiska styrningar, till exempel fjärrkontroll eller
        sol- och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>
        Monteras enkelt direkt på vägg, fönsterkarm eller i nisch.
      </p>
      <h3>Markisduk</h3>
      <p>
        Du har en rad dukar att välja mellan: Acryl (utomordentlig ljus- och
        väderbeständighet), och Screen (genomsiktlig PVC-belagd glasfiber som
        filtrerar solljuset). Screenkollektionen innehåller vävar med
        flamskyddande egenskaper och är hälso- och kvalitetscertifierade enligt
        Öko-tex och Enduris.
      </p>
      <h3>Markisstativ</h3>
      <p>
        MT64 finns som standard i vitt, grått och svart. MT74 finns i vitt och
        grått. Med lite längre leveranstid kan man få stativet i vilken RAL-färg
        man vill. Detta för att macha markisen med husets färg.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/markisolette_01.jpg',
      alt: 'Fönstermarkis',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/mt64_kassett.jpg',
        alt: 'MT 64 Kassett',
      },
      {
        src: '/images/products/utomhus/markis/mt64_frontprofil.jpg',
        alt: 'MT 64 profil',
      },
      {
        src: '/images/products/utomhus/markis/mt74_kassett.jpg',
        alt: 'MT 74 kassett',
      },
      {
        src: '/images/products/utomhus/markis/mt74_frontprofil.jpg',
        alt: 'MT 74 profil',
      },
    ],
  },
]

module.exports = markiser

<template>
  <b-container fluid class="m-0 p-0 svg-background" tag="footer">
    <Suppliers />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220" class="bg">
      <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="gradient">
        <stop stop-color="#523f95" offset="0%" />
        <stop stop-color="#4f3a9c" offset="100%" />
      </linearGradient>
      <path
        fill="url(#gradient)"
        fill-opacity="1"
        d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,122.7C1120,107,1280,117,1360,122.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
    <b-row class="bg-color p-0 m-0 w-100 text-light text-center">
      <b-col sm="6" class="py-4">
        <h2>Kontakta oss</h2>
        <h3 class="my-3">Telefontider</h3>
        <p class="text-color">Måndag till fredag 08.00 till 16.00</p>
        <p class="text-color">Vänligen ring eller mejla för att boka tid.</p>
        <h3 class="my-3">Telefon</h3>
        <b-link class="link-syle" href="tel:+46739863050">
          <p>
            <b-icon icon="telephone-outbound"></b-icon>
            073-986 30 50
          </p>
        </b-link>
        <h3 class="my-3">Mejladress</h3>
        <b-link class="link-syle" href="mailto:info@a-solskydd.se">
          <b-icon icon="envelope"></b-icon>
          info@a-solskydd.se
        </b-link>
      </b-col>
      <b-col sm="6" class="py-4">
        <h2>Sociala medier</h2>
        <p>
          <b-link class="link-syle" href="https://www.facebook.com/asolskydd/">
            <b-icon icon="facebook" class="icon"></b-icon> Vi är på Facebook
          </b-link>
        </p>
        <p>
          <b-link class="link-syle" href="https://www.instagram.com/asolskydd/">
            <b-icon icon="instagram" class="icon"></b-icon> Vi är på Instagram
          </b-link>
        </p>
        <h3 class="my-3">Miljö</h3>
        <p class="text-color w-75 m-auto">
          Överbliven material, komponenter och kundernas ersatta produkter
          sorteras och överlämnas till Återvinningscentraler.
        </p>
        <img src="@/assets/images/miljo.jpg" alt="Miljö" class="mt-3" />
      </b-col>
      <p class="pl-3 text-color">Copyright &copy; A-SOLSKYDD, Stockholm 2021</p>
    </b-row>
  </b-container>
</template>

<script>
import Suppliers from '../views/Suppliers'

export default {
  name: 'Footer',
  components: {
    Suppliers,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .svg-background {
  background-color: rgb(87 20 255);
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="#0099ff" fill-opacity="1" d="M0,128L80,144C160,160,320,192,480,186.7C640,181,800,139,960,122.7C1120,107,1280,117,1360,122.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"%3E%3C/path%3E%3C/svg%3E');
  background-attachment: fixed;
  background-size: cover;
} */
.bg-color {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(8, 8, 75) 35%,
    rgba(82, 63, 149, 1) 100%,
    rgba(89, 78, 156, 1) 100%
  );
}
.text-color {
  color: rgb(217, 212, 250);
}

.bg {
  background-color: #e7e4ff;
}

.link-syle {
  color: rgb(190, 180, 253);
}

.icon {
  font-size: 2em;
  margin-right: 0.5em;
}
</style>

<template>
  <div>
    <b-form
      v-on:submit.prevent="sendEmail"
      method="POST"
      enctype="text/plain"
      class="m-3"
    >
      <b-form-group>
        <slot
          ><label><b-icon icon="person"></b-icon> Namn</label></slot
        >
        <b-form-input
          required
          name="name"
          type="text"
          v-model="message.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <slot
          ><label><b-icon icon="tag"></b-icon> Ämne</label></slot
        >
        <b-form-input
          required
          type="text"
          name="subject"
          v-model="message.subject"
        ></b-form-input>
      </b-form-group>
      <label><b-icon icon="pen"></b-icon> Meddelande</label>
      <b-form-textarea
        id="textarea"
        placeholder="Enter something..."
        rows="3"
        max-rows="6"
        name="body"
        v-model="message.body"
      ></b-form-textarea>
      <div class="text-center">
        <b-button type="submit" variant="primary" class="mt-5">
          Skicka <b-icon icon="reply"></b-icon>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: {},
    }
  },
  methods: {
    urlEncode(input) {
      return encodeURIComponent(input)
    },
    sendEmail() {
      console.log('open email: ', this.message)
      const subject = this.urlEncode(this.message.subject)
      let body = 'Meddelande från: ' + this.message.name + '\n'
      body = body + this.message.body
      body = this.urlEncode(body)
      window.location.href = `mailto:info@a-solskydd.se?subject=${subject}&body=${body}`
    },
  },
}
</script>

<style></style>

var solfilm = [
  {
    type: 'inomhus',
    category: 'Solfilm',
    name: 'Solfilm',
    description: `Stör det dig att folk kan se rakt in i din villa, sommarstuga eller lägenhet? Med solfilm och dekorfilm kan du skapa ett insynsskydd som försvårar den direkta insynen. På samma sätt så hjälper solfilmen dig på företaget att förhindra insyn utan att försämra utsikten`,
    modalText: `<h2>Solfilm</h2>
    <p>
      Många företag tvingas att köpa eller hyra luftkonditioneringsaggregat
      under heta sommardagar. Med en modern solfilm blir kontoret svalt, utan
      att energikostnaderna ökar. Men även på vintern kan en solfilm förbättra
      inomhusklimatet. Flera av våra filmer är konstruerade för att isolera
      glaset, vilket innebär minskat värmeutsläpp genom glasytan och därmed
      sparad energi. Att installera en modern solfilm i ditt hem är ett enkelt
      sätt att skapa en bättre inomhusmiljö.
    </p>
    <h2></h2>

    <h3>Svalt på sommaren...</h3>
    <p>
      Under heta sommardagar kan ditt hem bli olidligt varmt. Det finns många
      sätt att göra hemmet svalare. Du kan dra för gardiner, stänga persiennerna
      eller kanske installera luftkonditionering. Alternativt kan du låta
      installera en professionell solfilm. Solfilmer blockerar upp till 80
      procent av solens strålningsvärme.
    </p>
    <h3>Varmt på vintern</h3>
    <p>
      Även på vintern kan solfilm förbättra inomhusklimatet. Många solfilmer är
      konstruerade för att isolera glaset, vilket innebär mindre värmeutsläpp
      genom glasytan. Därmed sparar du energi och pengar.
    </p>
    <h3>Minskar blekning</h3>
    <p>
      När solen gassar slits inredningen i ditt hem. Gardiner, mattor och möbler
      bleks och gulnar av UV-strålar och värme. Med solfilm på fönstren
      blockeras upp till 98 procent av solens skadliga UV-strålning. Du har
      investerat mycket tid och pengar i att inreda ditt hem. Skydda din
      investering med solfilm.
    </p>
    <h3>Förhindrar insyn</h3>
    <p>
      Stör det dig att folk kan se rakt in i din villa, sommarstuga eller
      lägenhet? Med solfilm och dekorfilm kan du skapa ett insynsskydd som
      försvårar den direkta insynen. På samma sätt så hjälper solfilmen dig på
      företaget att förhindra insyn utan att försämra utsikten.
    </p>`,
    mainImage: {
      src: '/images/products/inomhus/078.jpg',
      alt: 'solfilm',
    },
    images: [],
  },
]

module.exports = solfilm

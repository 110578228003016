var vertikalmarkis = [
  {
    type: 'utomhus',
    category: 'Vertikalmarkis',
    name: 'Vertikalmarkis VS24-44',
    description: `VS24-44 är en kompakt Vertikalmarkis med smidig design. Den lämpar sig väl
      för fönsterapplikationer på bostäder, butiker och kontorsfastigheter.`,
    modalText: `<h2>Vertikalmarkis VS24-44</h2>
      <p>
        VS24-44 är en kompakt Vertikalmarkis med smidig design. Den lämpar sig väl
        för fönsterapplikationer på bostäder, butiker och kontorsfastigheter.
        Duken löper vertikalt längs utsidan av fönstret och bottenlisten styrs av
        linor längs dukens båda sidor. Screendukar finns i ett stort urval med
        bland annat flamskyddande egenskaper.
      </p>
      <h3>Manövrering</h3>
      <p>
        VS 24-44 manövreras som standard med band. Som tillval finns manövrering
        med vev eller motor. Vid motordrift går det att komplettera med
        automatiska styrningar, till exempel fjärrkontroll eller sol- och
        vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>Monteras enkelt direkt på vägg, fönsterfoder eller i nisch.</p>
      <h3>Markisstativ</h3>
      <p>Finns som standard i silver.</p>
      <h3>Markisduk</h3>
      <p>
        Vi har en bred kollektion med högkvalitativa screenvävar. Valet av
        materialets färg, vävteknik och öppenhetsfaktor avgör dukens förmåga att
        filtrera solljuset. Screenkollektionen innehåller vävar med flamskyddande
        egenskaper och är hälso- och kvalitetscertifierade enligt Öko-tex och
        Enduris. Välj från en bred kollektion.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/vertikalmarkis_vs24_44.jpg',
      alt: 'Vertikalmarkis',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/vertikalmarkis_kassett.jpg',
        alt: 'kassett',
      },
      {
        src: '/images/products/utomhus/markis/vertikalmarkis_styrning.jpg',
        alt: 'Styrning',
      },
      {
        src: '/images/products/utomhus/markis/vertikalmarkis_utankassett.jpg',
        alt: 'utan kassett',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Vertikalmarkis',
    name: 'Screen VS62 & 72',
    description: `VS62 och 72 är en stabil Screenmarkis med smidig design. VS62 lämpar sig
      väl för fönsterapplikationer på bostäder och den större VS72, lämpar sig
      för större applikationer på ex. butiker och kontorsfastigheter.`,
    modalText: `<h2>Screen VS62 & 72</h2>
      <p>
        VS62 och 72 är en stabil Screenmarkis med smidig design. VS62 lämpar sig
        väl för fönsterapplikationer på bostäder och den större VS72, lämpar sig
        för större applikationer på ex. butiker och kontorsfastigheter. Duken
        löper vertikalt längs utsidan av fönstret i styrskenor av aluminium.
        Screendukar finns i ett stort urval med bland annat flamskyddande
        egenskaper.
      </p>
      <h3>Manövrering</h3>
      <p>
        VS62 manövreras som standard med vevbox, VS72 med utvändig vev. Som
        tillval finns manövrering med motor. Vid motordrift går det att
        komplettera med automatiska styrningar, till exempel fjärrkontroll eller
        sol- och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>Monteras enkelt direkt på vägg, fönsterfoder eller i nisch.</p>
      <h3>Markisstativ</h3>
      <p>
        VS62 finns som standard i vitt, grått och svart. VS72 finns i vitt och
        grått. Med lite längre leveranstid kan man få sta tivet i vilken RAL-färg
        man vill. Detta för att matcha markisen med husets färg.
      </p>
      <h3>Markisduk</h3>
      <p>
        Vi har en bred kollektion med högkvalitativa screenvävar. Valet av
        materialets färg, vävteknik och öppenhetsfaktor avgör dukens förmåga att
        filtrera solljuset. Screenkollektionen innehåller vävar med flamskyddande
        egenskaper och är hälso- och kvalitetscertifierade enligt Öko-tex och
        Enduris. Välj från en bred kollektion.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/vs62.jpg',
      alt: 'VS 62',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/vs62kassett.jpg',
        alt: 'VS 62 med kassett',
      },
      {
        src: '/images/products/utomhus/markis/vs72kassett.jpg',
        alt: 'VS 72 med kassett',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Vertikalmarkis',
    name: 'Zip screen ZS82',
    description: `ZS82 är en Zip Screen som har sidostyrningar med zip-funktion. Detta
      innebär att screenvävens sidor är försedda med en dragkedja som löper i en
      glidprofil som håller väven på plats längs hela dess sidor.`,
    modalText: `<h2>Zip Scren ZS82</h2>
      <p>
        ZS82 är en Zip Screen som har sidostyrningar med zip-funktion. Detta
        innebär att screenvävens sidor är försedda med en dragkedja som löper i en
        glidprofil som håller väven på plats längs hela dess sidor. Denna
        konstruktion gör produkten utmärkt i vindutsatta lägen och eliminierar
        dessutom ljusspringan som finns på vanliga vertikalmarkiser.
      </p>
      <h3>Manövrering</h3>
      <p>
        Den här Zip Screenen är motoriserad. Som tillval finns fjärrkontroll eller
        automatiska styrningar som t.ex. sol- och vindautomatik.
      </p>
      <h3>Montering</h3>
      <p>Monteras mycket snabbt och enkelt direkt på vägg eller i nisch.</p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 2 färger: Vitt och Grått.
        Med något längre leveranstid kan man få stativet i ytterligare 10 färger,
        matchade för att passa ihop med de vanligast förekommande husfärger och
        fasader.
      </p>
      <h3>Screenväv</h3>
      <p>
        ZS 82 är testad och klassificerad med GeoScreen, Everview, Soltis 92,
        Soltis B92 och Serge.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/zs82.jpg',
      alt: 'ZS82',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/zs82gavel.jpg',
        alt: 'ZS82 gavel',
      },
      {
        src: '/images/products/utomhus/markis/zs82dragkedja.jpg',
        alt: 'ZS82 dragkedja',
      },
    ],
  },
]
module.exports = vertikalmarkis

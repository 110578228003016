var lamellGardiner = [
  {
    type: 'inomhus',
    category: 'Lamellgardiner',
    name: 'Lamellgardiner',
    description: `Allt populärare - både i hem- och kontorsmiljö. Lamellgardinen är en
      elegant inredningsdetalj med många funktioner. Används som solskydd,
      rumsavskiljare, i kontorsinredningar, samlingslokaler och på inglasade
      balkonger.`,
    modalText: `<h2>Lamellgardiner</h2>
      <p>
        Allt populärare - både i hem- och kontorsmiljö. Lamellgardinen är en
        elegant inredningsdetalj med många funktioner. Används som solskydd,
        rumsavskiljare, i kontorsinredningar, samlingslokaler och på inglasade
        balkonger. Smidig lösning för stora fönsterytor. Ge oss dina önskemål så
        skräddarsyr vi efter dina specifika mått, även för snedtak. Välj mellan
        olika modeller, strukturer och färger i vårt stora sortiment av väv. Vi
        använder oss av kvalitets-textiler från det svenska väveriet Almedals, i
        Kinna.
      </p>`,
    mainImage: {
      src: '/images/products/inomhus/lamellgardiner/lamellgardiner.jpg',
      alt: 'Lamellgardin',
    },
    images: [
      {
        src: '/images/products/inomhus/lamellgardiner/lammellbilder.jpg',
        alt: 'lamellgardin',
      },
    ],
  },
]
module.exports = lamellGardiner

<template>
  <div class="w-100">
    <hero :imageLink="img" :imgAlt="imgAlt" :text="text" />
    <b-container class="flex-grow-1" tag="main">
      <h2 class="h2">Om oss</h2>
      <p>
        A-SOLSKYDD marknadsför och säljer samt monterar alla slags
        solskyddsprodukter. Vi lyssnar noggrant till kundernas behov och
        önskemål och skapar därefter anpassade lösningar. Dessutom lämnar vi
        alltid 3 års garanti på material och arbete. Våra kunder är
        privatpersoner, kommuner, byggföretag, fastighetsägare,
        fastighetsförvaltare m.fl. Vi har ett brett sortiment som
        tillfredställer de flesta önskemål inom solskydd och vårt främsta mål är
        nöjda kunder.
      </p>
      <p>
        Vi installerar markiser, solskydd balkong, solskydd altan, solskydd
        fönster, solfilm, rullgardine, persienner och insäktsnät i Stockholm
        söderort och hela Stockholmsområdet
      </p>
    </b-container>
  </div>
</template>
<script>
import Hero from '@/components/Hero'
const img = require('@/assets/images/hero/hiss.jpg')
const imgAlt = 'img'

export default {
  data() {
    return {
      img: img,
      imgAlt: imgAlt,
      text: 'Om Oss',
    }
  },
  components: {
    Hero,
  },
}
</script>

<style lang="scss" scoped></style>

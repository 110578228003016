<template>
  <b-container tag="section" fluid class="bg-discount">
    <b-container tag="article" class="pt-5 wx-auto w-75">
      <h2 class="h2">Samarbetspartners</h2>
      <div class="d-flex justify-content-between flex-wrap">
        <div
          class="supplier-container"
          v-for="supplier in suppliers"
          :key="supplier.src"
        >
          <a v-if="supplier.href" class="supplier-link" :href="supplier.href">
            <img
              class="supplier-image"
              :src="supplier.src"
              :alt="supplier.alt"
            />
          </a>
          <img
            v-else
            class="supplier-image"
            :src="supplier.src"
            :alt="supplier.alt"
          />
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<script>
const hsb = require('@/assets/images/supplier/hsb.png')
const sdb = require('@/assets/images/supplier/sdb.png')
const ahs = require('@/assets/images/supplier/ahs.png')
const dickson = require('@/assets/images/supplier/dickson.jpg')
const becker = require('@/assets/images/supplier/becker.jpg')
const sandatex = require('@/assets/images/supplier/sandatex.png')
const somfy = require('@/assets/images/supplier/somfy.svg')
const clearview = require('@/assets/images/supplier/clearview.png')
const GSW = require('@/assets/images/supplier/gsw.gif')
const lagun = require('@/assets/images/supplier/lagun.png')
// @ is an alias to /src

export default {
  name: 'Suppliers',
  data() {
    return {
      suppliers: [
        {
          href: 'https://www.hsb.se/stockholm/',
          src: hsb,
          alt: 'HSB',
        },
        {
          href: 'http://www.scandinaviandesignerblinds.com/',
          src: sdb,
          alt: 'Scandinavian designer blinds',
        },
        {
          src: ahs,
          href: 'https://www.ahssolskydd.se/',
          alt: 'Ah:s solskydd',
        },
        {
          href: 'https://www.dickson-constant.com/se/',
          src: dickson,
          alt: 'Dickson',
        },
        {
          href: 'https://www.becker-antriebe.com/se',
          src: becker,
          alt: 'Becker',
        },
        {
          href: 'https://www.somfy.se',
          src: somfy,
          alt: 'Somfy',
        },
        {
          href: 'https://www.sandatex.se',
          src: sandatex,
          alt: 'Somfy',
        },
        {
          href: 'https://www.clearview.se/',
          src: clearview,
          alt: 'Clearview',
        },
        {
          href: 'https://www.lagun.se/',
          src: lagun,
          alt: 'Lagun',
        },
        {
          href: 'https://www.gsw.se/',
          src: GSW,
          alt: 'GSW',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.bg-discount {
  background-color: #e7e4ff;
}

.supplier-container {
  width: 10em;
  height: 5em;
  margin-bottom: 2em;
  display: flex;
}

a.supplier-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
img.supplier-image {
  width: 100%;
  object-fit: scale-down;
  filter: grayscale(100%);
}
img.supplier-image:hover,
img.supplier-image:focus {
  filter: grayscale(0%);
}

@media screen and (max-width: 730px) {
  .supplier-container {
    width: 6em;
    height: 3em;
  }
}
</style>

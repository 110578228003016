var persienner = [
  {
    type: 'inomhus',
    category: 'Persienn',
    name: 'No hole persienn',
    description: `Hålen i persiennlamellerna är osynliga vilket blockerar ljusinsläpp.
      Lamellerna täcker nämligen hålen när persiennen är stängd, vilket också
      ger en enhetlig och elegant look.`,
    mainImage: {
      src: '/images/products/inomhus/persienner/nohole_01.jpg',
      alt: 'No hole persienn',
    },
    images: [
      {
        src: '/images/products/inomhus/persienner/nohole_02.jpg',
        alt: 'Röd no hole persienn',
      },
    ],
  },
  {
    type: 'inomhus',
    category: 'Persienn',
    name: '16mm Persienn',
    description: `16mm persiennen är framtagen för montering i fönster
      där luftspalten mellan glasen är mindre än normalt. I
      vissa fall kan persiennen även monteras i spröjsade fönster`,
    mainImage: {
      src: '/images/products/inomhus/persienner/16mm.jpg',
      alt: '16mm persienn',
    },
    images: [],
  },
  {
    type: 'inomhus',
    category: 'Persienn',
    name: 'PERSIENN Roulett',
    description: `Roulettpersienner är perfekt i rummen där du tillbringar mycket tid – du
      kan styra dagsljuset som du vill. Öppna persiennen för att möta solen,
      vinkla den för att sila ljuset upp i taket eller stänga den för att hindra
      insyn.`,
    modalText: `<h2>PERSIENN Roulett</h2>
      <p>
        Roulettpersienner är perfekt i rummen där du tillbringar mycket tid – du
        kan styra dagsljuset som du vill. Öppna persiennen för att möta solen,
        vinkla den för att sila ljuset upp i taket eller stänga den för att hindra
        insyn. Om du bara lämnar persiennen lite öppen har du själv ögonkontakt
        med omvärlden. Persienner anpassar du efter dygnet, vädret och årstiden.
        Det är därför de aldrig blir omoderna. Nu kan du också få Roulett med
        designlist som gör att den integreras snyggt i fönstret.
      </p>
      <h3>Manövrering Roulett</h3>
      <p>
        kan monteras frihängande eller mellan fönsterglas.Basmodellerna manövreras
        med lina och vridstång och finns i flera utseenden och funktioner. Du kan
        få en persienn som flyttas steglöst upp eller ned i fönstret, och öppnas
        från båda håll med diskreta handtag och vred istället för linor - vår
        tvåvägsmanövrerade modell. Det är elegant, praktiskt och säkert. Du kan
        även få en som manövreras genom att du tar tag i underlisten och flyt- tar
        denna till önskat läge. Roulett kan också manövreras med kulkedja.
      </p>
      <h3>Skräddarsydd</h3>
      <p>
        Hur persiennen manövreras är en del av den personliga utformningen av
        Roulett. Roulett finns i många färger och mönster, är alltid måttbeställd
        och kundanpassad för att passa perfekt i dina fönster och ditt hem.
      </p>
      <h3>Eleganta lösningar</h3>
      <p>
        Vill du inte matcha färgen på soffan så kanske en spegelblank,
        värmereflekterande persienn passar bättre? Då kan du spara energi bara
        genom att vinkla persiennen. Vill du att persiennen ska vara diskret och
        integreras med fönstret så väljer du den nya Designlisten. Vill du ha ett
        riktigt soft ljus med mjuka skuggor i rummet ska du titta på en perforerad
        persienn. Den har små, små hål som gör förvånansvärt stor skillnad.
      </p>
      <h3>Miljövänlig</h3>
      <p>
        Materialet i persiennen är till stor del återvunnet, vilket sparar energi
        och miljö. Lister och lameller av aluminium och stål kan lämnas till
        returåtervinning igen. Mellanglas Roulett.
      </p>
  
      <h3>Barnsäkerhet:</h3>
      <p>
        Köpare och användare av solskydd bör vara medvetna om riskerna med löst
        hängande linor, snoddar samt ögleformade kedjor och linor - de kan utgöra
        faror för små barn. Frihängande Roulett
      </p>
  
      <p>Dessa riktlinjer ska alltid följas.</p>
      <ul>
        <li>
          Små barn kan strypas av öglor bildade av draglinor, band och inre linor
          som används för att manövrera produkten. De kan även råka vira linor
          runt halsen.
        </li>
        <li>
          För att undvika att barn stryps eller trasslar in sig ska linorna hållas
          utom räckhåll för små barn.
        </li>
        <li>sängar, barnsängar och andra möbler bort från solskyddens linor.</li>
        <li>Knyt inte ihop linorna.</li>
        <li>
          Kontrollera att att linorna inte trasslar ihop sig och bildar en ögla.
        </li>
        <li>
          Installera och använd säkerhetsanordningar enligt
          installationsanvisningarna på produkterna för att minska olycksrisken.
        </li>
      </ul>
  
      <h3>Övrigt</h3>
      <p>
        Motoriserade persienner finns i vitt och silver och byggs med en överlist
        som är 25x25 mm. *** Persienner med vävda band byggs på ett system som har
        något större överlist, 38x28 mm.
      </p>
      <p>
        Registered Trademark of Hunter Douglas Scandinavia AB, Sweden. Artikelnr:
        9101302015
      </p>`,
    mainImage: {
      src: 'images/products/inomhus/persienner/25mm_01.jpg',
      alt: 'Roulette persienn',
    },
    images: [],
  },
  {
    type: 'inomhus',
    category: 'Persienn',
    name: '35 mm Persienn',
    description: `En populär persien med 35-mm lameller, som är gjorda av en speciell
      aluminiumlegering som brännlackeras för att hålla färg och lyster under
      hela användningstiden.`,
    modalText: `Stegsnörena, alltså de som binder ihop lamellerna
      vertikalt, finns i ett stort antal kulörer. Man kan lätt anpassa färgerna
      till möbler och rumsmiljö för att skapa önskad atmosfär. Genom att dölja
      hålen i lamellerna ger de vävda banden med en stängd persienn ett elegant
      utseende. Alla komponenter i pesienn-konstruktionen är tillverkade i
      noggrant utvalda stål- och plastmaterial för bästa möjliga hållbarhet.`,
    mainImage: {
      src: '/images/products/inomhus/persienner/35mm.jpg',
      alt: '35 mm persienn',
    },
    images: [],
  },
  {
    type: 'inomhus',
    category: 'Persienn',
    name: 'Persienn Classic 50',
    description: `Classic 50 är en persienn avsedd för 50 mm lameller i aluminium. Den
      robusta designen är lämplig för stora glaspartier där den med fördel
      ersätter traditionella gardiner – i till exempel kontor, sjukhus, skolor
      och affärer`,
    modalText: `<h2>PERSIENN Classic 50</h2>
      <p>
        Classic 50 är en persienn avsedd för 50 mm lameller i aluminium. Den
        robusta designen är lämplig för stora glaspartier där den med fördel
        ersätter traditionella gardiner – i till exempel kontor, sjukhus, skolor
        och affärer. Finns med stegsnören eller vävda band (25 och 38 mm) i en
        mängd färger. Genom att färgmatcha lameller och band skapar du enkelt en
        persienn med rätt karaktär för varje rum.
      </p>
      <h3>Manövrering</h3>
      <p>
        Upp- och nedhissning sker manuellt med polyesterlinor. Linorna leds genom
        ett fallsäkrat låshus. Man frigör linorna genom att föra dem mot centrum
        på persiennen, sträcka dem och därefter försiktigt släppa efter. För att
        låsa persiennen återför man linorna till en vertikal position. Man vinklar
        lamellerna med linor. Vridstång finns som tillval. Det går också att
        motorisera Classic 50 som då manövreras med fjärrkontroll.
      </p>
      <h3>Montering</h3>
      <p>
        Classic 50 är avsedd för frihängande montage på fönsterkarm eller i nisch.
        Upp- hängningsbeslagen gör det enkelt att sätta upp eller ta ned
        persiennen utan några verktyg.
      </p>
      <h3>Barnsäkerhet</h3>
      <p>
        Dessa riktlinjer ska alltid följas. • Små barn kan strypas av öglor
        bildade av draglinor, band och inre linor som används för att manövrera
        produkten. De kan även råka vira linor runt halsen. • För att undvika att
        barn stryps eller trasslar in sig ska linorna hållas utom räckhåll för små
        barn. • Flytta sängar, barnsängar och andra möbler bort från solskyddens
        linor. • Knyt inte ihop linorna. • Kontrollera att linorna inte trasslar
        ihop sig och bildar en ögla. • Installera och använd säkerhetsanordningar
        enligt installationsanvisningarna på produkterna för att minska
        olycksrisken
      </p>`,
    mainImage: {
      src: '/images/products/inomhus/persienner/55mm_01.jpg',
      alt: '50 mm persienn',
    },
    images: [
      {
        src: '/images/products/inomhus/persienner/50mm_lameller.jpg',
        alt: 'Lameller',
      },
    ],
  },
  {
    type: 'inomhus',
    category: 'Persienn',
    name: 'Träpersienner',
    description: `Ett exklusivt insynsskydd, tillverkat av Scandinavian Designer Blinds
      (SDB) på Åland. Deras persienner finns installerade över hela världen och
      är tillverkade av antingen nordiska eller odlade exotiska träslag.`,
    modalText: `Ett exklusivt insynsskydd, tillverkat av Scandinavian Designer Blinds
      (SDB) på Åland. Deras persienner finns installerade över hela världen och
      är tillverkade av antingen nordiska eller odlade exotiska träslag.
      Persiennerna avpassas exakt för varje design, uppdrag och placering. SDB
      inbjuder gärna inredningsdesigners att experimentera med funktion, färg
      och form för att skapa något mer än bara ett solskydd. En träpersienn från
      SDB är en inredningsdetalj och fönsterutsmyckning av högsta kvalité.`,
    mainImage: {
      src: '/images/products/inomhus/persienner/wood.jpg',
      alt: '50 mm persienn',
    },
    images: [],
  },
]

module.exports = persienner

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const generateMetaTags = ({ title, description }) => {
  const site = 'A-Solskydd'
  const url = 'https://a-solskydd.se'

  const image = `${process.env.VUE_APP_SITE_URL}/images/header3.gif`

  return {
    title: `${title} - ${site}`,
    ...(description && {
      metaTags: [
        // General
        { name: 'description', content: description },
        // Google
        { itemprop: 'name', content: site },
        { itemprop: 'url', content: url },
        { itemprop: 'description', content: description },
        { itemprop: 'thumbnailUrl', content: image },
        { itemprop: 'image', content: image },
        // Facebook
        { name: 'og:site_name', content: site },
        { name: 'og:title', content: `${title} - ${site}` },
        { name: 'og:url', content: url },
        { name: 'og:type', content: 'website' },
        { name: 'og:description', content: description },
        { name: 'og:image', content: image },
        { name: 'og:image:width', content: '430' },
        { name: 'og:image:height', content: '502' },
        // Twitter
        { name: 'twitter:title', content: site },
        { name: 'twitter:url', content: url },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: image },
      ],
    }),
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      ...generateMetaTags({
        title: 'Hem',
        description: `A-Solskydd säljer och monterar solskyddsprodukter, 
        markiser, persienner, balkongskydd, solfilm. Vi utför arbeten i Enskede och hela Stockholm`,
      }),
    },
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      ...generateMetaTags({
        title: 'Om',
        description: `A-Solskydd marknadsför och säljer samt monterar alla slags solskyddsprodukter. Vi lyssnar noggrant till kundernas behov och
        önskemål och skapar därefter anpassade lösningar. Dessutom lämnar vi
        alltid 3 års garanti på material och arbete.`,
      }),
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    meta: {
      ...generateMetaTags({
        title: 'Produkter',
        description: `A-Solskydd marknadsför och säljer samt monterar alla slags solskyddsprodukter, som markiser, persienner, balkongskydd, solfilm. `,
      }),
    },
    // route level code-splitting
    // this generates a separate chunk (products.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "products" */ '../views/Products.vue'),
  },
  {
    path: '/references',
    name: 'References',
    // route level code-splitting
    // this generates a separate chunk (references.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "references" */ '../views/References.vue'),
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  Array.from(document.querySelectorAll('[data-vue-router-controlled')).forEach(
    el => {
      if (el.parentNode) {
        el.parentNode.removeChild(el)
      }
    }
  )
  if (!nearestWithMeta) {
    return next()
  }
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')
      return tag
    })
    .forEach(tag => document.head.appendChild(tag))
  return next()
})

export default router

<template>
  <div class="header-bg">
    <b-navbar toggleable="sm">
      <b-navbar-brand to="/">
        <img src="../assets/images/header3.gif" alt="a-solskydd" class="logo" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x"></b-icon>
          <b-icon v-else icon="list"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/">Hem</b-nav-item>
          <b-nav-item to="/about">Om</b-nav-item>
          <b-nav-item to="/products">Produkter</b-nav-item>
          <b-nav-item to="/references">Referenser</b-nav-item>
          <b-nav-item href="/#offer">Offert</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    msg: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$text-color: #3f3e3e;
$text-color-inverted: #fff;
$background-color: #fff;
// $hover-bg: rgb(41, 41, 41);

.logo {
  width: 15em;
}
.header-bg {
  background-color: #ffffff70;
}

.ml-auto {
  text-transform: uppercase;
  font-size: 1.5em;
  font-family: 'Bebas Neue', cursive;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:after,
.navbar-light .navbar-nav .nav-link:before {
  transition: all 0.5s;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: $text-color;
}

.navbar-light .navbar-nav .nav-link {
  position: relative;
}
.navbar-light .navbar-nav .nav-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: linear-gradient(
    0deg,
    rgba(6, 1, 95, 0.472) 0%,
    rgba(21, 21, 109, 0.465) 35%,
    rgba(82, 63, 149, 0.5) 100%,
    rgba(88, 78, 156, 0.52) 100%
  );
  height: 1px;
}
.navbar-light .navbar-nav .nav-link:hover:after {
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  transition: all 2s;
  color: $text-color;
}

.navbar-light .navbar-nav .nav-link:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: $text-color-inverted;
  z-index: 1;
}
.navbar-light .navbar-nav .nav-link:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgb(8, 8, 75) 35%,
      rgba(82, 63, 149, 1) 100%,
      rgba(89, 78, 156, 1) 100%
    );
  }
}
@media screen and (max-width: 680px) {
  .logo {
    width: 9em;
  }
}
</style>

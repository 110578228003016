var terassmarkis = [
  {
    type: 'utomhus',
    category: 'Terassmarkis',
    name: 'Terassmarkiser FA22 & FA24',
    description: `FA20 markiserna är speciellt utvecklade för den lilla terrassen eller
      balkongen med alla de egenskaper som utmärkt Hunter Douglas Scandinavia's
      markiser genom åren med hög kvalitet och lång hållbarhet.`,
    modalText: `<h2>Terassmarkiser FA22 & FA24</h2>
      <p></p>
      <p>
        FA20 markiserna är speciellt utvecklade för den lilla terrassen eller
        balkongen med alla de egenskaper som utmärkt Hunter Douglas Scandinavia's
        markiser genom åren med hög kvalitet och lång hållbarhet. Den nya designen
        på markisens armar tillsammans med Dyneema®-band över knäleder ger en
        dukspänning utöver det vanliga!
      </p>
      <h3>FA22</h3>
      <p>
        FA22 är en öppen markis i kompaktutförande. Detta är instegsmarkisen i vår
        nya 20 serie. FA22 är utrustad med traditionella gavlar och ger mycket
        markis för pengarna.
      </p>
      <h3>FA24</h3>
      <p>
        FA24 är en öppen, kompakt markis i de- signutförande. Markisen är utrustad
        med specialdesignade gavlar som knyter ihop den fyrkantiga formen på
        bärröret med det runda valsröret på ett sätt som ger den ett modernt
        uttryck.
      </p>
      <h3>Manövrering</h3>
      <p>
        Markisen manövreras med utvändig vev som standard. Som tillval finns
        manövering med motor. Vid motordrift går det att komplettera med
        automatiska styrningar, till exempel fjärrkontroll eller sol- och
        vindautomatik.
      </p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 3 färger: Vitt, Grått och
        Svart med struktur. Med lite längre leveranstid kan man får stativet i
        vilken RAL-färg man vill. Detta för att matcha ihop markisen med husets
        färg.
      </p>
      <h3>Montering</h3>
      <p>Monteras enkelt direkt på vägg eller i tak.</p>
      <h3>Tillval</h3>
      <p>På båda modellerna i serien kan man välja till takprofil.</p>
      <h3>Markisduk</h3>
      <p>
        Du har en rad akryldukar med utomordentlig ljus och väderbeständighet att
        välja mellan. Välj från en bred kollektion med allt från enfärgade till
        randiga vävar.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/fa22.jpg',
      alt: 'Terassmarkis FA22',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/fa22_front.jpg',
        alt: 'FA 22 front',
      },
      {
        src: '/images/products/utomhus/markis/fa22_gavel.jpg',
        alt: 'FA22 Gavel',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Terassmarkis',
    name: 'Terassmarkiser FA40',
    description: `FA40 är en mångsidig serie terrassmarkiser som täcker de flesta behov av
      skugga över en uteplats. Med allt från markiser med extra ståhöjd till
      riktigt smala eller varför inte med rullgardinsfront för de där
      irriterande låga solstrålarna.`,
    modalText: `<h2>Terrassmarkis FA40</h2>
      <p>
        FA40 är en mångsidig serie terrassmarkiser som täcker de flesta behov av
        skugga över en uteplats. Med allt från markiser med extra ståhöjd till
        riktigt smala eller varför inte med rullgardinsfront för de där
        irriterande låga solstrålarna. En stabil markis i många modeller, med
        modern design i ett stort urval kulörer kännetecknar FA40-serien. Hög
        kvalitet borgar för slitstyrkan. 5 års Nordic Light garanti.
      </p>
      <section class="border-bottom">
        <h3>FA42 Classic</h3>
        <p>
          Öppen terrassmarkis med traditionella gavlar. Integsprodukten i
          standardutförande. <br />
          Pitchcontrol, tak, rullkappa och teleskopstag.
        </p>
        <ul>
          <li>Max bredd: 7,0 m.</li>
          <li>Max utfall: 4,0 m. (Vid 7 m bredd, max utfall 3.5 m)</li>
        </ul>
      </section>
      <section class="border-bottom">
        <h3>FA44 Design</h3>
        <p>
          Öppen terrassmarkis i designutförande. Gavlar med modern design som
          elegant förenar olika former. <br />
          Tillval: Pitchcontrol, tak, rullkappa och teleskopstag.
        </p>
        <ul>
          <li>Max bredd: 7,0 m.</li>
          <li>Max utfall: 4,0 m. (Vid 7 m bredd, max utfall 3.5 m)</li>
        </ul>
      </section>
      <section class="border-bottom">
        <h3>FA45 Crossover</h3>
        <p>
          Öppen terrassmarkis med Cross Overarmar. Ena armen viks omlott över den
          andra, vilket gör det möjligt att ha terrassmarkisen på en mycket smal
          terrass eller balkong. Tillval: Tak, rullkappa och teleskopstag.
        </p>
        <ul>
          <li>Minsta bredd är 1 meter.</li>
          <li>Max bredd: 4,6 m.</li>
          <li>Max utfall: 4,0 m</li>
        </ul>
      </section>
      <section class="border-bottom">
        <h3>FA46</h3>
        <p>
          Öppen terassmarkis med dukbalk. Duken har en bruten profil som ger extra
          ståhöjd för att till exempel kunna öppa en altandörr helt. En bit ut
          från fasaden vinklas duken ned för att ge skugga.
        </p>
        <ul>
          <li>Max bredd: 7,0 m.</li>
          <li>Max utfall: 4,0 m (Vid 7m bredd, max utfall 3,5m)</li>
          <li>Tillval: Pitchcontrol, tak, rullkappa och teleskopstag.</li>
        </ul>
      </section>
      <section class="border-bottom">
        <h3>FA48 - Halvkassett</h3>
        <p>
          Kassetten skyddar duken mot väder och vind och ger den längre livslängd.
          I infällt läge är duken helt dold. Endast motordrift.<br />
          Tillval: Pitchcontrol, rullkappa och teleskopstag.
        </p>
        <ul>
          <li>Max bredd: 7,0 m.</li>
          <li>Max utfall: 3,5 m</li>
        </ul>
  
      </section>
      <h3>Tillval</h3>
  
      <h2>FA 40</h2>
      <h3>Duk</h3>
      <p>
        Välj från en bred vävkollektion, från en- färgade till randiga. Kappan är
        löstagbar och kan fås med rakt eller vågigt avslut.
      </p>
      <h3>Stativ</h3>
      <p>
        Med DyneemaR-band över vikarmarnas knäleder och armfäste får markiserna en
        god, hållbar dukspänning.
      </p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i tre färger:
        pulverlackerad svart, vit och grått. Med något längre leveranstid kan man
        få stativet i ytterligare 10 färger – matchade för att passa ihop med de
        vanligast förekommande hus och fasader. Utöver dessa färger erbjuds valfri
        RAL-kulör, dock med något längre leveranstid.
      </p>
      <h3>Manövrering</h3>
      <p>
        Markisen manövreras manuellt med vev eller med motordrift. Tillsammans med
        motordrift finns sol- och vindautomatik
      </p>
      <h3>Tillval: Nedrullningsbar kappa</h3>
      <p>
        En nedrullningsbar kappa i markisens front löser enkelt problem med lågt
        stående sol. Finns med markisduk eller genomsiktlig screenväv. Manövreras
        med vev.
      </p>
      <h3>Tillval: Justerenhet</h3>
      <p>
        Steglös inställning av markisens vinkel. Gör det enkelt att justera
        markisen efter hur högt solen står under dygnet. Manövreras med vev.
      </p>
      <h3>Tillval: Teleskopstag</h3>
      <p>
        Justerbara teleskopstag som fälls ned vid behov och säkrar markisens front
        mot underlaget. Fotplatta medföljer. Stabiliserar markisen vid vindbyar
        (upp till 10 m/s). Steglös höjd 0,8 - 2,5 m.
      </p>
      <h3>Tillval: Tak</h3>
      <p>
        Gör markisen mer komplett och tillför en trevlig design. Taket håller
        också borta smuts och löv från markisen när den är inrullad.
      </p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/fa40.jpg',
      alt: 'Terassmarkis FA40',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/fa42standard.jpg',
        alt: 'FA 42 standard gavel',
      },
      {
        src: '/images/products/utomhus/markis/fa44gavel.jpg',
        alt: 'FA44 Gavel',
      },
      {
        src: '/images/products/utomhus/markis/fa45tak1.jpg',
        alt: 'FA 45 tak närbild',
      },
      {
        src: '/images/products/utomhus/markis/fa45tak2.jpg',
        alt: 'FA 45 tak',
      },
      {
        src: '/images/products/utomhus/markis/fa46.jpg',
        alt: 'FA 46',
      },
      {
        src: '/images/products/utomhus/markis/fa48.jpg',
        alt: 'FA 48',
      },
      {
        src: '/images/products/utomhus/markis/fa40rullkappa.jpg',
        alt: 'Rullkappa',
      },
      {
        src: '/images/products/utomhus/markis/fa40tak.jpg',
        alt: 'Tak',
      },
      {
        src: '/images/products/utomhus/markis/fa40pitchcontrol.jpg',
        alt: 'Pitchkontrol',
      },
      {
        src: '/images/products/utomhus/markis/fa40teleskopstag.jpg',
        alt: 'Teleskopstag',
      },
    ],
  },
  {
    type: 'utomhus',
    category: 'Terassmarkis',
    name: 'Terassmarkiser FA62 & FA64',
    description: `FA 60 terrassmarkis är en mycket attraktiv helkassett som ger ett
      stilfullt intryck och lämpar sig väl för exempelvis terrasser, balkonger,
      butiker, kiosker och restauranger.`,
    modalText: `<h2>Terrassmarkis FA62 & FA64</h2>
      <p>
        FA 60 terrassmarkis är en mycket attraktiv helkassett som ger ett
        stilfullt intryck och lämpar sig väl för exempelvis terrasser, balkonger,
        butiker, kiosker och restauranger. Utmärkande drag för FA 60 serien är den
        designade helkassetten som skyddar duk och mekanism mot regn och smuts. De
        rejäla vikarmarna med Dyneema® band och starka fjädrar garanterar en bra
        dukspänning.
      </p>
      <h3>Manövrering</h3>
      <p>
        Utvändig eller invändig manövrering med motor. Möjlighet till att
        komplettera med automatisk styrning, till exempel fjärrkontroll och sol-
        och vindautomatik.
      </p>
      <h3>Markisduk</h3>
      <p>
        Välj från en bred kollektion med allt från enfärgade till randiga vävar.
      </p>
      <h3>Montering</h3>
      <p>Markisen monteras enkelt direkt på väggen eller i takfoten.</p>
      <h3>Markisstativ</h3>
      <p>
        Markisstativet med detaljer finns som standard i 3 färger: Vitt, Grått och
        Svart med struktur. Med lite längre leveranstid kan man få stativet i
        vilken RAL-färg man vill. Detta för att matcha markisen med husets färg.
      </p>
      <h3>Vikarmar</h3>
      <p>
        Armprofilerna är tillverkade av strängpressat aluminium och
        armkomponenterna av pressgjutet aluminium. Vikarmarna har Dyneema® band
        över knäled för ökad stabilitet. Extra kraftiga fjädrar ger en utmärkt
        dukspänning.
      </p>
      <h3>Tillval</h3>
      <p>Dekorelement i kontrastfärg.</p>`,
    mainImage: {
      src: '/images/products/utomhus/markis/fa60.jpg',
      alt: 'Terassmarkis FA 60',
    },
    images: [
      {
        src: '/images/products/utomhus/markis/fa60front.jpg',
        alt: 'FA 60 front',
      },
      {
        src: '/images/products/utomhus/markis/fa64sidovy.jpg',
        alt: 'FA 64 sidovy',
      },
    ],
  },
]

module.exports = terassmarkis
